/* eslint-disable */
import Vue from 'vue'
import Router from 'vue-router'
import Dash from "./Dash";

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: '',
      component: Dash,
      children: [
        {
          path: '/',
          name: 'home',
          component: () => import(/* webpackChunkName: "home" */ './views/Home/Home.vue'),
          meta: { title: 'Home', canonical: '/' }
        },
        {
          path: '/a-empresa',
          name: 'A Empresa',
          component: () => import(/* webpackChunkName: "empresa" */ './views/Empresa/Empresa.vue'),
          meta: { title: 'A Empresa', canonical: '/a-empresa' }
        },
        {
          path: '/planos',
          name: 'Planos',
          component: () => import(/* webpackChunkName: "planos" */ './views/Planos/Planos.vue'),
          meta: { title: 'Planos', canonical: '/planos' }
        },
        {
          path: '/automacao',
          name: 'Automacao',
          component: () => import(/* webpackChunkName: "automacao" */ './views/Automacao/Automacao.vue'),
          meta: { title: 'Automação', canonical: '/automacao' }
        },
        {
          path: '/automacao/:id',
          name: 'AutomacaoDetalhes',
          component: () => import(/* webpackChunkName: "automacao-detalhes" */ './views/Automacao/AutomacaoDetalhes.vue'),
          meta: { title: 'Detalhes da Automação', canonical: '/automacao/:id' }
        },
        {
          path: '/contato',
          name: 'Contato',
          component: () => import(/* webpackChunkName: "contato" */ './views/Contato/Contato.vue'),
          meta: { title: 'Contato', canonical: '/contato' }
        },
        {
          path: '/galeria',
          name: 'Galeria',
          component: () => import(/* webpackChunkName: "galeria" */ './views/Galeria/Galeria.vue'),
          meta: { title: 'Galeria', canonical: '/galeria' }
        },
        {
          path: '/galeria/:id',
          name: 'GaleriaId',
          component: () => import(/* webpackChunkName: "galeria-detalhes" */ './views/Galeria/Galeria.vue'),
          meta: { title: 'Galeria', canonical: '/galeria/:id' }
        },
        {
          path: '*',
          name: 'PaginaNaoEncontrada',
          component: () => import(/* webpackChunkName: "404" */ './views/404.vue'),
          meta: { title: 'Página Não Encontrada', canonical: '/404' }
        }
      ]
    },
    {
      path: '*',
      name: '404',
      component: () => import(/* webpackChunkName: "404" */ './views/404.vue'),
      meta: { title: 'Página Não Encontrada', canonical: '/404' }
    }
  ]
})


router.beforeEach((to, from, next) => {
  document.title = to.meta && to.meta.title ? to.meta.title + ' - Prodata' : 'Prodata';
  next();
});

router.afterEach((to) => {
  const linkCanonical = document.querySelector("link[rel='canonical']");
  const canonicalUrl = window.location.origin + ( window.location.pathname||to.meta?.canonical);


  if (linkCanonical) {
    linkCanonical.setAttribute("href", canonicalUrl);
  } else {
    const link = document.createElement("link");
    link.setAttribute("rel", "canonical");
    link.setAttribute("href", canonicalUrl);
    document.head.appendChild(link);
  }
});
export default router
